import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactUs.css";

export const ContactUs = () => {
  const form = useRef();
  const [buttonPopup, setButtonPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fkphjnl",
        "template_ig6dapk",
        form.current,
        "In7OlImhULm4ibxUF"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setButtonPopup(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="formBody">
        <div className="formRow">
          <div className="leftColumnCell">
            <input
              required
              className="inputField"
              placeholder="Name"
              type="name"
              name="user_name"
            />
          </div>
          <div className="rightColumnCell">
            <input
              required
              className="inputField"
              placeholder="Email"
              type="email"
              name="user_email"
            />
          </div>
        </div>
        <div className="formRow">
          <div className="leftColumnCell">
            <input
              className="inputField"
              placeholder="Phone"
              type="number"
              name="user_phone"
            />
          </div>
          <div className="rightColumnCell">
            <input
              className="inputField"
              placeholder="Address"
              type="text"
              name="user_address"
            />
          </div>
        </div>
        <div className="formRow">
          <input
            className="inputField"
            placeholder="Subject"
            type="text"
            name="user_subject"
          />
        </div>
        <div style={{ width: "100%", marginBottom: 24 }}>
          <textarea
            required
            placeholder={"Type your message here..."}
            style={{
              width: "100%",
              height: 160,
              paddingLeft: 5,
              paddingTop: 5,
              backgroundColor: "transparent",
              borderColor: "white",
              color: "white",
              borderStyle: "solid",
              borderWidth: "1.5px",
            }}
            name="message"
          />
        </div>
        {!buttonPopup && (
          <input
            style={{
              width: "100%",
              backgroundColor: "#F5F4F2",
              height: 50,
              border: "1px solid white",
              fontSize: 20,
              fontWeight: "400",
              color: "black",
            }}
            type="submit"
            value="Submit"
          />
        )}

        {buttonPopup && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#F5F4F2",
              height: 50,
              border: "1px solid white",
              fontSize: 20,
              fontWeight: "400",
              color: "green",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Message Sent!
          </div>
        )}
      </div>
    </form>
  );
};

export default ContactUs;
